<template>
  <button
    :type="type"
    :class="buttonClasses"
    :disabled="loading || disabled"
    v-bind="$attrs"
  >
    <slot />
  </button>
</template>

<script>
import variant from '@/mixins/variant.js';
import size from '@/mixins/size.js';
export default {
  name: 'RButton',
  mixins: [variant, size],
  props: {
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClasses() {
      return [
        'button',
        `button--${this.variant}`,
        `button--size--${this.size}`,
        this.wide ? `button--wide` : null,
        this.disabled ? `button--disabled` : null,
        this.inactive ? `button--inactive` : null,
        this.outline ? `button--outline` : null,
        this.round ? `button--round` : null,
        this.loading ? `button--loading` : null
      ];
    }
  }
};
</script>
