import DashboardBase from '@/views/dashboard/base.vue';
import NotFound from '@/views/errors/not-found.vue';

const routes = [
    {
    path: '/dashboard',
    component: DashboardBase,
    children: [
      // {
      //   path: '',
      //   name: 'dashboard',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "dashboard" */
      //       '@/views/dashboard/dashboard.vue'
      //     )
      // },
      // {
      //   path: "/instructions",
      //   name: "instructions",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "instructions" */
      //       '@/views/instructions/instructions.vue'
      //     )
      // },
    ]
  },

  // REGISTRATION v1
  {
    path: "/registration",
    name: "registration",
    component: () =>
      import(
        /* webpackChunkName: "registration" */
        '@/views/registration/index.vue'
      )
  },
  {
    path: "/registration/signin",
    name: "registration.signin",
    component: () =>
      import(
        /* webpackChunkName: "registration.signin" */
        '@/views/registration/registration-signin.vue'
      )
  },
  {
    path: "/registration/signin/confirmation",
    name: "registration.signin.confirmation",
    component: () =>
      import(
        /* webpackChunkName: "signin.confirmation" */
        '@/views/registration/signin-confirmation.vue'
      )
  },
  {
    path: "/registration/reset-password",
    name: "registration.reset-password",
    component: () =>
      import(
        /* webpackChunkName: "registration.reset-password" */
        '@/views/registration/reset-password.vue'
      )
  },
  {
    path: "/registration/reset-password/confirmation",
    name: "registration.reset-password.confirmation",
    component: () =>
      import(
        /* webpackChunkName: "registration.reset-password.confirmation" */
        '@/views/registration/reset-password-confirmation.vue'
      )
  },
  {
    path: "/registration/reset-password/new-password",
    name: "registration.new-password",
    component: () =>
      import(
        /* webpackChunkName: "registration.new-password" */
        '@/views/registration/new-password.vue'
      )
  },
  {
    path: "/registration/signup",
    name: "registration.signup",
    component: () =>
      import(
        /* webpackChunkName: "registration.signup" */
        '@/views/registration/registration-signup.vue'
      )
  },
  {
    path: "/registration/signup/confirmation",
    name: "registration.signup.confirmation",
    component: () =>
      import(
        /* webpackChunkName: "signup.confirmation" */
        '@/views/registration/signup-confirmation.vue'
      )
  },
  {
    path: "/registration/invite",
    name: "registration.invite",
    component: () =>
      import(
        /* webpackChunkName: "registration.invite" */
        '@/views/registration/registration-invite.vue'
      )
  },
  {
    path: "/registration/plans",
    name: "registration.plans",
    component: () =>
      import(
        /* webpackChunkName: "registration.plans" */
        '@/views/registration/plans.vue'
      )
  },
  {
    path: "/registration/plans/selected",
    name: "registration.plans.selected",
    component: () =>
      import(
        /* webpackChunkName: "registration.plans.selected" */
        '@/views/registration/plans-selected.vue'
      )
  },
  {
    path: "/registration/payment/success",
    name: "registration.payment.success",
    component: () =>
      import(
        /* webpackChunkName: "registration.payment.success" */
        '@/views/registration/payment-success.vue'
      )
  },

  // REGISTRATION V2
  {
    path: "/",
    name: "registration.v2",
    component: () =>
      import(
        /* webpackChunkName: "registration.v2" */
        '@/views/registration-v2/index.vue'
      )
  },
  {
    path: "/registration/v2/welcome",
    name: "registration.v2.welcome",
    component: () =>
      import(
        /* webpackChunkName: "registration.v2.welcome" */
        '@/views/registration-v2/welcome.vue'
      )
  },
  {
    path: "/registration/v2/signup/name",
    name: "registration.v2.signup.name",
    component: () =>
      import(
        /* webpackChunkName: "registration.v2.signup.name" */
        '@/views/registration-v2/signup-name.vue'
      )
  },
  {
    path: "/registration/v2/signup/email",
    name: "registration.v2.signup.email",
    component: () =>
      import(
        /* webpackChunkName: "registration.v2.signup.email" */
        '@/views/registration-v2/signup-email.vue'
      )
  },
  {
    path: "/registration/v2/email/verified",
    name: "registration.v2.email.verified",
    component: () =>
      import(
        /* webpackChunkName: "registration.v2.email.verified" */
        '@/views/registration-v2/email-verified.vue'
      )
  },
  {
    path: "/registration/v2/signup/phone",
    name: "registration.v2.signup.phone",
    component: () =>
      import(
        /* webpackChunkName: "registration.v2.signup.phone" */
        '@/views/registration-v2/signup-phone.vue'
      )
  },
  {
    path: "/registration/v2/signup/password",
    name: "registration.v2.signup.password",
    component: () =>
      import(
        /* webpackChunkName: "registration.v2.signup.password" */
        '@/views/registration-v2/signup-password.vue'
      )
  },
  {
    path: "/registration/v2/signin",
    name: "registration.v2.signin",
    component: () =>
      import(
        /* webpackChunkName: "registration.v2.signin" */
        '@/views/registration-v2/signin.vue'
      )
  },
  {
    path: "/registration/v2/official-member",
    name: "registration.v2.official.member",
    component: () =>
      import(
        /* webpackChunkName: "registration.v2.official.member" */
        '@/views/registration-v2/official-member.vue'
      )
  },
  {
    path: "/registration/v2/setup/profile",
    name: "registration.v2.setup.profile",
    component: () =>
      import(
        /* webpackChunkName: "registration.v2.setup.profile" */
        '@/views/registration-v2/setup-profile.vue'
      )
  },
  // REGISTRATION V2

  // SETUP PROFILE V2
  {
    path: "/v2/profile/welcome",
    name: "setup.profile.welcome",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.welcome" */
        '@/views/setup-profile/welcome.vue'
      )
  },
  {
    path: "/v2/profile/basic",
    name: "v2.profile.basic",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.basic" */
        '@/views/setup-profile/basic-profile.vue'
      )
  },
  {
    path: "/v2/profile/about",
    name: "v2.profile.about",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.about" */
        '@/views/setup-profile/about.vue'
      )
  },
  {
    path: "/v2/profile/experience",
    name: "v2.profile.experience",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.experience" */
        '@/views/setup-profile/experience.vue'
      )
  },
  {
    path: "/v2/profile/education",
    name: "v2.profile.education",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.education" */
        '@/views/setup-profile/education.vue'
      )
  },
  {
    path: "/v2/profile/places",
    name: "v2.profile.places",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.places" */
        '@/views/setup-profile/places.vue'
      )
  },
  {
    path: "/v2/profile/great-job",
    name: "v2.profile.great_job",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.great_job" */
        '@/views/setup-profile/great-job.vue'
      )
  },
  {
    path: "/v2/profile/images",
    name: "v2.profile.images",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.images" */
        '@/views/setup-profile/images.vue'
      )
  },
  {
    path: "/v2/profile/travels",
    name: "v2.profile.travels",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.travels" */
        '@/views/setup-profile/travels.vue'
      )
  },
  {
    path: "/v2/profile/notable-experiences",
    name: "v2.profile.notable_experiences",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.notable_experiences" */
        '@/views/setup-profile/notable-experiences.vue'
      )
  },
  {
    path: "/v2/profile/ambitions",
    name: "v2.profile.ambitions",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.ambitions" */
        '@/views/setup-profile/ambitions.vue'
      )
  },
  {
    path: "/v2/profile/cares",
    name: "v2.profile.cares",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.cares" */
        '@/views/setup-profile/cares.vue'
      )
  },
  {
    path: "/v2/profile/finish-later",
    name: "v2.profile.finish_later",
    component: () =>
      import(
        /* webpackChunkName: "setup.profile.finish_later" */
        '@/views/setup-profile/finish-later.vue'
      )
  },
  // SETUP PROFILE V2




  {
    path: "/onboarding",
    name: "Onboarding",
    component: () =>
      import(
        /* webpackChunkName: "onboarding" */
        '@/views/onboarding/onboarding.vue'
      )
  },
  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(
        /* webpackChunkName: "signup" */
        '@/views/signup/signup.vue'
      )
  },
  {
    path: "/signup/confirmation",
    name: "signup.confirmation",
    component: () =>
      import(
        /* webpackChunkName: "signup.confirmation" */
        '@/views/signup/confirmation.vue'
      )
  },
  {
    path: "/signin",
    name: "signin",
    component: () =>
      import(
        /* webpackChunkName: "signin" */
        '@/views/signin/signin.vue'
      )
  },
  {
    path: "/forgot-password",
    name: "forgot.password",
    component: () =>
      import(
        /* webpackChunkName: "forgot.password" */
        '@/views/forgot-password/forgot-password.vue'
      )
  },
  {
    path: "/reset-password",
    name: "reset.password",
    component: () =>
      import(
        /* webpackChunkName: "reset.password" */
        '@/views/reset-password/reset-password.vue'
      )
  },
  {
    path: "/claim-profile",
    name: "claim.profile",
    component: () =>
      import(
        /* webpackChunkName: "claim-profile" */
        '@/views/claim-profile/claim-profile.vue'
      )
  },
  {
    path: "/setup-options",
    name: "setup.options",
    component: () =>
      import(
        /* webpackChunkName: "setup-options" */
        '@/views/setup-options/setup-options.vue'
      )
  },
  {
    path: "/connect-network",
    name: "connect.network",
    component: () =>
      import(
        /* webpackChunkName: "connect-network" */
        '@/views/connect-network/connect-network.vue'
      )
  },
  {
    path: "/enhance-profile",
    name: "enhance.profile",
    component: () =>
      import(
        /* webpackChunkName: "enhance-profile" */
        '@/views/enhance-profile/enhance-profile.vue'
      )
  },
  {
    path: "/interests",
    name: "interests",
    component: () =>
      import(
        /* webpackChunkName: "interests" */
        '@/views/interests/interests.vue'
      )
  },
  {
    path: "/profile-completed",
    name: "profile-completed",
    component: () =>
      import(
        /* webpackChunkName: "profile-completed" */
        '@/views/profile-completed/profile-completed.vue'
      )
  },
  {
    path: "/public-profile",
    name: "public-profile",
    component: () =>
      import(
        /* webpackChunkName: "public-profile" */
        '@/views/public-profile/public-profile.vue'
      )
  },
  {
    path: "/my-network",
    name: "my-network",
    component: () =>
      import(
        /* webpackChunkName: "my-network" */
        '@/views/my-network/my-network.vue'
      )
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not.found',
    component: NotFound
  }
];

export default routes;
