<template>
  <div class="bg-gray-200 h-screen">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>