import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB-2tfy1Qc12MeDB-gDHiMh7cpgC6vzaSE",
  authDomain: "leafy-sunrise-283522.firebaseapp.com",
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
