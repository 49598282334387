// Pollyfills
require('es6-promise/auto');

import { createApp } from 'vue'
import App from './App.vue'
import i18n from "./services/messages"
import router from './router'
import { createMetaManager, plugin as vueMeta } from 'vue-meta'
import ui from './utils/ui';
import './css/app.css';

/* eslint-disable */
import firebaseApp from "./firebaseapp";
/* eslint-enable */

createApp(App)
  .use(vueMeta)
  .use(createMetaManager())
  .use(i18n)
  .use(router)
  .use(ui)
  .mount('#app')
