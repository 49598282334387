export default {
  props: {
    shadow: {
      type: String,
      default: 'lg',
      validator: function (value) {
        return (
          [
            "",
            'xxs',
            'xs',
            'sm',
            'md',
            'lg',
            'xl',
            '2xl',
            '3xl',
            '4xl'
          ].indexOf(value) !== -1
        )
      }
    }
  },
  computed: {
    shadowClass () {
      return this.shadow ? `shadow-${this.shadow}` : 'shadow-lg'
    }
  }
}
