import Button from '@/components/ui/forms/button.vue';
import Card from '@/components/ui/card.vue';

const components = [
  Button,
  Card
];

export const Plugin = {
  install(app) {
    components.forEach(component => app.component(component.name, component));
  }
};

export default Plugin;

export { components };
