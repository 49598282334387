<template>
  <div :class="['card', shadowClass]">
    <slot name="header">
      <div 
        v-if="hasHeader || hasActions"
        class="card__header"
      >
        <div
          class="card__header__inner"
        >
          <div
            class="ml-2 mt-2"
          >
            <h3 class="card__header__inner_title">
              {{ title }}
            </h3>
            <p
              v-if="hasDescription"
              class="card__header__inner_description"
            >
              <slot name="description" />
            </p>
          </div>
          <div class="flex-shrink-0">
            <slot name="actions" />
          </div>
        </div>
      </div>
    </slot>
    <slot />
    <div
      v-if="hasFooter"
      class="py-4 border-t border-gray-200"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import shadow from '@/mixins/shadow';
export default {
  name: 'RCard',
  mixins: [shadow],
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasHeader() {
      return !!this.$slots['header'] || this.title;
    },
    hasActions() {
      return !!this.$slots['actions'];
    },
    hasFooter() {
      return !!this.$slots['footer'];
    },
    hasDescription() {
      return !!this.$slots['description'];
    }
  }
};
</script>