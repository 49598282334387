const colors = {
  'gallery': '#EDEDED',
  'black': '#000000',
  'white': '#ffffff',
  'primary': '#83C03B',
  'secondary': '#FF6600',
  'linkedin-blue': '#1066C0',
  'information-blue': '#377CE1',
  'orange': '#FF6600',
  'warning': '#FCCD14',
  'success': '#83C03B',
  'infographic-purple': '#332C54',
  'infographic-green': '#B1CC34',
  'infographic-pink': '#C776B2',
  'infographic-gray': '#999799',
  'infographic-black': '#171219',
  'base-platinum': '#142328',
  'light-black': '#242424',
  transparent: 'transparent'
}

const outline = {}

const textColor = {}

const borderColor = textColor;

const boxShadow = {}

module.exports = {
  colors,
  outline,
  boxShadow,
  textColor,
  borderColor
}
