const { colors } = require('@/../colors.config.js')

export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return (
          ['', ...Object.keys(colors)].indexOf(value) !== -1
        );
      }
    }
  }
};